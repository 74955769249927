<template>
  <v-container fluid>
    <WarehouseDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></WarehouseDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('warehouse.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
      <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
        </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import WarehouseDialog from "../dialogs/WarehouseDialog";
import BaseTable from "./BaseTable.vue";
export default {
    data: vm => ({
        url: "/container/warehouse/",
        headersMap: {
            id: {
                sortable: true,
                text: vm.$i18n.t("ID"),
                value: "id",
            },
            name: {
                sortable: true,
                text: vm.$i18n.t("name"),
                value: "name",
            },
            address: {
                sortable: true,
                text: vm.$i18n.t("warehouse.address"),
                value: "address",
            },
            contact_person: {
                sortable: true,
                text: vm.$i18n.t("warehouse.contact_person"),
                value: "contact_person",
            },
            contact_phone: {
                sortable: true,
                text: vm.$i18n.t("warehouse.contact_phone"),
                value: "contact_phone",
            },
            actions: {
                sortable: false,
                text: vm.$i18n.t("actions"),
                value: "actions",
                width: "10%"
            }
        },
        tcHeaders: ['id', 'name', 'address', 'contact_person', 'contact_phone', 'actions'],
    }),
    mixins: [BaseTable],
    components: {
        WarehouseDialog
    },
}
</script>